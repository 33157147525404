.addBrd{
    border:1px solid #f0e2e2;
}
.childshowhide{
    display: none;
}
.childExpand{
    padding-right: 8px;
    font-size: 12px;
    cursor: pointer;
}
.borderTop{
    border-top: 1px solid #e8e8e8;
    font-size: 13px;
    ul li{
        padding: 5px;
    }
}