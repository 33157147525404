/*
 * PageInfo css File
*/

.form-group {
    margin-bottom: 1.2rem;
}
.flexgrp{
    display: flex;
    .form-check{
        width: 50%;
    }
}