/*
 * Componant css File
*/

.custError {
    color: red;
    font-size: 11px;
}
.formAction .form-group{
    margin-bottom: 4px;
}