.card-body{ padding:0px!important; }
.alignCenter{ text-align:center; }
.add-actions-btn{ margin-bottom: 5px; }
.errorMsg{ color: red; }
ul.pagination li a{ cursor: pointer; }

.tow_level{ background-color: #e3f2fd; }
.three_level{ background-color: #bbdefb; }
.four_level{ background-color: #ce93d8; }

  