.react-strap-table .card-header label {
    margin-bottom: 0;
}

.react-strap-table .table-sort-th {
    cursor: pointer;
}

.react-strap-table .table-sort-icon {
    float: right;
}

.react-strap-table .card-header {
    background-color: rgba(0, 0, 0, 0);
    padding: 0px 0px 8px 0px;
}

.react-strap-table .card-footer {
    background-color: rgba(0, 0, 0, 0);
    padding-top: 0px;
    border-top: 0px;
}

.react-strap-table .card-body {
    padding-bottom: 0px;
}
.custom-header-style{
    background-color: #C5E0FF;
}
.custcard{
    border: none;
}
.cust-table{
    border: 1px solid #e1e1e1;
}
.sortOrder{
    position: relative;
    border-left: 1px solid #000;
}
.sortUp{
    position: absolute;
    left: 96%;
    width: 9px;
    height: 12px;
    top:-22px;
    cursor:pointer;
}
.sortDown{
    position: absolute;
    left: 96%;
    width: 9px;
    height: 12px;
    bottom: -3px;
    cursor: pointer;
}
.childshowhide{
    display: none;
}
.childClassView ul li{
    list-style-type:none;
    padding: 3px;
}
.childClassView td{
    border-right: none;
}
